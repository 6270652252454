.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.App-logo {
  font-size: 12rem;
  letter-spacing: 1rem;
  color: rgb(33, 33, 33);
}

.App-header {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: whitesmoke;
}

.App-link {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-image {
  height: 80%;
}

@media (max-width: 1368px) and (orientation: portrait) {
  .App-image {
    height: unset;
    width: 80%;
  }

  .App-logo {
    font-size: 10rem;
  }
}

@media (max-width: 1368px) and (orientation: landscape) {
  .App-logo {
    font-size: 10rem;
  }
}

@media (max-width: 767px) and (orientation: portrait) {
  .App-image {
    height: unset;
    width: 80%;
  }

  .App-logo {
    font-size: 6rem;
  }
}

@media (max-width: 767px) and (orientation: landscape) {
  .App-logo {
    font-size: 6rem;
  }
}

@media screen and (orientation: landscape) {
}

body::-webkit-scrollbar {
  display: none;
}
