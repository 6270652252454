body {
  margin: 0;
  font-family: "DM Serif Display", serif;
  background-color: rgb(33, 33, 33);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
